@import "../../ui/variables.scss";

.attributes {
  dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  dt {
    width: calc(100% - 140px);
    flex-shrink: 0;
    flex-grow: 0;
  }

  dd {
    white-space: nowrap;
  }

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 0 0 0 6px;

    &.active {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}

.dark-mode {
  .attributes {
    span {
      background-color: rgba(0, 0, 0, 0.3);
    }

    .active {
      background-color: var(--link-hovered);
    }
  }
}
