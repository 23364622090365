@import "../../ui/media.scss";

.tips {
  &.page {
    padding: 30px 0 300px 0;

    @include tablet {
      padding: 50px 0 300px 0;
    }
  }

  .tips-wrapper {
    max-width: $tablet;
    margin: 0 auto;
    position: relative;
  }

  .video {
    aspect-ratio: 16 / 9;
    width: 100%;
  }

  // .posts,
  // .games {
  //   button {
  //     box-shadow: 0 4px 16px -7px var(--link-shadow);
  //     background-color: var(--link);
  //     font-weight: 700;
  //     text-align: center;
  //     width: 120px;
  //     color: var(--white);
  //     border-radius: var(--input-radius);
  //     height: var(--button-line-height);
  //     line-height: var(--button-line-height);
  //     font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
  //       "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  //       "Droid Sans", "Helvetica Neue", sans-serif;

  //     @include tablet {
  //       height: var(--button-line-height-tablet);
  //       line-height: var(--button-line-height-tablet);
  //     }
  //   }
  // }
}
