.paper {
  max-width: 600px;
  margin: 0 auto;

  .paper-circle {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #090c17;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px 0 0;
    font-weight: 700;
    font-size: 2.2rem;
  }
}

.paper-item {
  border: 1px solid #b9c0de;
  border-bottom: 0;
  display: flex;
  align-items: center;

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 10px 0 0 0;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 1px solid #b9c0de;
    margin: 0 0 100px 0;
  }

  .paper-answer,
  .paper-index {
    height: 42px;
  }

  .paper-index {
    flex: 0 0 180px;
    border-right: 1px solid #b9c0de;
    display: flex;
    align-items: center;
    padding: 0 14px;
  }

  .paper-answer {
    flex: 0 0 calc(100% - 180px);
  }
}

.paper-dot {
  .paper-index {
    text-transform: uppercase;
    justify-content: center;
    flex: 0 0 42px;
  }

  .paper-answer {
    flex: 0 0 calc(100% - 42px);
  }
}
