@import '../../ui/media.scss';

.animated-bg {

  position: relative;

  svg {
    width: 100%;
    max-width: 80px;
    position: absolute;
    right: 20%;
    top: -20px;
    overflow: visible;

    @include tablet {
      right: 10%;
      top: -40px;
      max-width: 150px;
    }

    @include desktop {
      right: 0;
    }

    path {
      fill: #ddd;
      fill: #f7f7f7;
      fill-opacity: 0.2;
      stroke: none;
      fill: blue;

      &:first-child {
        fill: red;
        transform: rotate(14deg);
      }

      &:last-child {
        fill: green;
        transform: rotate(-14deg);
      }
    }
  }
}

.dark-mode {
  .animated-bg {
    path {
      fill: #86b0da;
      fill-opacity: 0.3;

      &:first-child {
        fill: #6cdc9e;
      }

      &:last-child {
        fill: #ba6cdc;
      }
    }
  }
}