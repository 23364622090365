@import "../../../ui/variables.scss";
@import "../../../ui/media.scss";

// reset
button {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  display: inline-block;
  font-size: var(--font-button);
  font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  @include tablet {
    font-size: var(--font-button-tablet);
  }
}

.status-button {
  &.block {
    display: block;
    width: 100%;

    & > div {
      display: block;
      width: 100%;
    }
  }
}

.button-surface,
.status-button-placeholder,
.button-simple {
  font-weight: 700;
  text-align: center;
  padding: 0;
  color: var(--white);
  border-radius: var(--input-radius);
  height: var(--button-line-height);
  line-height: var(--button-line-height);
  font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  @include tablet {
    height: var(--button-line-height-tablet);
    line-height: var(--button-line-height-tablet);
  }
}

.button-surface.button-default {
  box-shadow: 0 4px 16px -7px var(--link-shadow);
  background-color: var(--link);
  transition: background-color 200ms ease, box-shadow 200ms ease;
  cursor: pointer;
}

.ghost {
  .button-surface.button-default {
    box-shadow: 0 4px 16px -7px var(--link-shadow);
    background-color: var(--white);
    border: 2px solid var(--link);
    color: var(--link);
    line-height: calc(var(--button-line-height) - 4px);
    transition: color 200ms ease, border 200ms ease, box-shadow 200ms ease;

    @include tablet {
      line-height: calc(var(--button-line-height-tablet) - 4px);
    }
  }
}

.status-button-placeholder {
  visibility: hidden;
  padding: 0 16px;
}

.status-button {
  perspective: 300px;

  & > div {
    display: inline-block;
    position: relative;
    pointer-events: none;
  }

  &.has-focus,
  &.is-hovered {
    .button-surface.button-default {
      background-color: var(--link-hovered);
      box-shadow: 0 4px 16px -7px transparent;
    }
  }

  &.ghost {
    &.has-focus.default,
    &.is-hovered.default {
      .button-surface {
        background-color: var(--white);
        border: 2px solid var(--link-hovered);
        color: var(--link-hovered);
        box-shadow: 0 0 10px -3px transparent;
      }
    }
  }

  &.empty {
    visibility: hidden;
    pointer-events: none;
  }

  &.pending {
    cursor: wait;
  }

  &.ghost .button-surface.button-pending,
  .button-surface.button-pending {
    box-shadow: 0 4px 16px -7px transparent;
    background-color: var(--pending);
    border: 2px solid var(--pending);
  }

  &.error {
    cursor: pointer;
    pointer-events: none;
  }

  .button-surface.button-error {
    box-shadow: 0 4px 16px -7px transparent;
    background-color: var(--error);
  }

  &.success {
    cursor: pointer;
    pointer-events: none;
  }

  .button-surface.button-success {
    box-shadow: 0 4px 16px -7px transparent;
    background-color: var(--success);
  }

  &.disabled {
    cursor: not-allowed;
  }

  .button-surface.button-disabled {
    box-shadow: 0 4px 16px -7px transparent;
    background-color: var(--pending);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: white;
    stroke: none;
    vertical-align: middle;
    position: relative;
    top: -2px;
    right: 0;
  }

  .icon-edit {
    top: -1px;
    right: -1px;
  }
}

.action.ghost.default,
.action.ghost {
  svg {
    fill: var(--link);
  }
}

.action.ghost.success,
.action.ghost.pending,
.action.ghost.disabled,
.action.ghost.error {
  svg {
    fill: var(--white);
  }
}

.button-flipper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  height: var(--button-line-height);

  @include tablet {
    height: var(--button-line-height-tablet);
  }
}

.action {
  .status-button-placeholder {
    padding: 0;
    width: var(--button-line-height);

    @include tablet {
      width: var(--button-line-height-tablet);
    }
  }

  .icon-pending {
    margin: 0;
  }

  .button-surface {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 22px;
      height: 22px;
      right: 0;
      top: 0;
    }

    .icon-edit {
      top: -1px;
      right: -1px;
    }
  }

  .button-flipper {
    width: var(--button-line-height);
    text-align: center;
    padding: 0;

    @include tablet {
      width: var(--button-line-height-tablet);
    }
  }
}

.button-back,
.button-front {
  backface-visibility: hidden;
  transform-origin: center;
  z-index: 11;
  background-color: transparent;
  transform: rotateX(0deg);
  position: absolute;
  top: 0;
  left: 0;
  height: var(--button-line-height);
  width: 100%;

  @include tablet {
    height: var(--button-line-height-tablet);
  }
}

.button-back {
  transform: rotateX(180deg);
  z-index: 10;
}

.action {
  .button-back {
    transform: rotateY(180deg);
  }
  .button-front {
    transform: rotateY(0deg);
  }
}

.icon-pending {
  span {
    animation-name: none;
  }
}

.spinner-anim-ready {
  .icon-pending {
    span {
      animation-name: bump;
    }
  }
}
