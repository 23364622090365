.bubbles-logo {
  width: 100%;
  height: 0;
  padding: 0 0 135.7142% 0;
  position: relative;

  canvas {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
}
