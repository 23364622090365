@import "../ui/variables.scss";
@import "../ui/media.scss";

.home {
  background-color: var(--background-tone);

  .banner {
    background-color: var(--background-card);
    padding: 10px calc(20% + 25px) 30px calc(20% + 25px);
    width: 140%;
    margin: 0 0 120px -20%;
    transform: rotate(-5deg) translate3d(0, 0, 0);
    box-shadow: 0 0 30px -15px #554b6d;
    // background-image: url("/images/CirclePattern.svg");

    @include tablet {
      padding: 30px calc(20% + 25px) 60px calc(20% + 25px);
      margin: 0 0 150px -20%;
    }

    @include desktop {
      margin: 0 0 180px -20%;
    }

    article {
      &:last-child {
        display: none;

        @include tablet {
          display: block;
        }
      }
    }

    article {
      position: relative;
      padding: 0;
    }

    p {
      line-height: 1.4;

      &:first-of-type {
        font-size: 30px;
        font-family: "Playfair display";
        font-style: italic;
        font-weight: 900;
        line-height: 1.3;
        margin: 10px 0 30px 0;

        @include tablet {
          font-size: 35px;
          line-height: 1.2;
          margin: 0 0 30px 0;
        }

        @include desktop {
          font-size: 45px;
        }
      }
    }

    h2 {
      position: relative;
      top: -50px;
      left: 0;
      font-size: 20px;
      margin: 0;
      white-space: nowrap;
      line-height: 1;

      @include tablet {
        font-size: 22px;
        top: -80px;
      }
    }
  }

  .banner-do {
    h2 {
      &:after {
        width: calc(100% - 120px);
        top: 12px;

        @include tablet {
          width: calc(100% - 135px);
        }
      }
    }
  }

  .mobile-banner-know {
    padding: 0;

    h2 {
      &:after {
        width: calc(100% - 148px);
      }
    }

    @include tablet {
      display: none;
    }
  }

  .banner-know {
    h2 {
      &:after {
        width: calc(100% - 120px);
        top: 12px;

        @include tablet {
          width: calc(100% - 160px);
        }
      }
    }
  }

  .testamonials {
    // margin: 40px auto 100px auto;

    // @include tablet {
    //   margin: 200px auto;
    // }

    // @include desktop {
    //   margin: 300px auto 200px auto;
    // }

    max-width: $desktop;
    margin: 120px auto 80px auto;

    @include tablet {
      margin: 240px auto 80px auto;
    }

    h2 {
      &:after {
        width: calc(100% - 145px);

        @include tablet {
          width: calc(100% - 185px);
        }
      }
    }
  }

  .top {
    padding: 70px 0 0 0;
    min-height: 70vh;

    @include tablet {
      padding: 100px 0 0 0;
      min-height: 65vh;
    }

    @include desktop {
      // padding: 140px 0 0 0;
      min-height: 75vh;
    }

    @include desktop-plus {
      padding: 200px 0 0 0;
    }
  }
}
