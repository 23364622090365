@import "../ui/media.scss";
@import "../ui/variables.scss";
.no-match {
  background-color: var(--background-tone);

  article {
    position: relative;
    max-width: $landscape;

    @include tablet {
      min-height: 60vh;
      max-width: 600px;
    }
  }

  .not-found-ingress {
    text-align: center;
    position: relative;
    z-index: 10;

    @include landscape {
      text-align: left;
    }
  }

  .not-found-logo {
    @include landscape {
      position: absolute;
      z-index: 9;
      width: 240px;
      top: -60px;
      right: -30px;
      pointer-events: none;
    }

    @include tablet {
      width: 340px;
    }
  }
}
