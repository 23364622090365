@import "./variables.scss";
@import "./media.scss";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.8;
  font-size: var(--font-regular);
  color: var(--text-color);
  font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include tablet {
    font-size: var(--font-regular-tablet);
  }
}

.dark-mode {
  color: var(--white);
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 900;
  line-height: 1.6;
  margin: 40px 0 14px 0;

  @include tablet {
    line-height: 1.4;
  }

  &:first-child {
    margin: 0 0 14px 0;
  }
}

a {
  color: var(--link);
  font-weight: 600;
  transition: color 300ms ease;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    color: var(--link-hovered);
  }
}

p {
  margin: 14px 0;
  line-height: 1.8;

  &:first-child {
    margin: 0 0 14px 0;
  }
}

h1 {
  font-size: 30px;

  @include tablet {
    font-size: 34px;
  }
}

h2 {
  font-size: 20px;

  @include tablet {
    font-size: 26px;
  }
}

h3 {
  font-size: 20px;

  @include tablet {
    font-size: 22px;
  }
}

i {
  font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: italic;
}

strong {
  font-weight: 900;
}

.playfair {
  font-family: "Playfair Display", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
}

.playfair-italic {
  font-family: "Playfair Display", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: italic;
  font-weight: 400;
}

b {
  font-weight: 700;
}
