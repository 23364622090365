.nav-anim {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  pointer-events: none;
  white-space: nowrap;
  width: 100%;
  height: 100%;

  .nav-anim-left,
  .nav-anim-right {
    position: absolute;
    top: 0;

    & > div {
      width: 1px;
      background-color: #eaeaea;
      height: 100vh;
    }
  }

  .nav-anim-left {
    left: 0;
    min-height: 100vh;
    background-color: red;
    background-color: #fff;
    justify-content: flex-end;
    display: flex;
  }

  .nav-anim-right {
    min-height: 100vh;
    background-color: blue;
    background-color: #fff;
    right: 0;
  }
}
