@import "../../ui/media.scss";
@import "../../ui/variables.scss";

.testamonials-wrapper {
  position: relative;
  line-height: 1.5;
  padding: 40px 0;
  min-height: 250px;

  @include tablet {
    padding: 80px 0;
    min-height: 325px;
  }

  figure {
    max-width: $desktop;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 2s;
    animation-iteration-count: 1;
    opacity: 0;
    z-index: 10;
    transform: translate3d(0, 0, 0);

    &.fade-in {
      animation-name: blurIn;
      animation-direction: normal;
      opacity: 1;
    }

    &.fade-out {
      animation-name: blurOut;
      animation-direction: normal;
    }
  }
}

@keyframes blurIn {
  0% {
    opacity: 0;
    filter: blur(10px);
  }

  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes blurOut {
  0% {
    opacity: 1;
    filter: blur(0);
  }

  100% {
    opacity: 0;
    filter: blur(10px);
  }
}
