@import "../../ui/media.scss";

.dark-mode {
  .top {
    .lovelo {
      text-shadow: 0 10px 20px #261a3b;
    }
  }
}

.top {
  min-height: 50vh;
  text-align: center;
  max-width: $desktop;
  margin: 0 auto;

  @include tablet {
    display: flex;
    flex-wrap: wrap;
    padding: 80px 0 100px 0;
    flex-wrap: nowrap;
  }

  @include desktop {
    padding: 80px 0;
  }

  @include desktop-plus {
    padding: 100px 0 140px 0;
  }

  h1 {
    @include tablet {
      flex: 1 0 auto;
    }
  }

  .top-text {
    position: relative;

    @include tablet {
      flex-basis: 100%;
      order: 2;
      text-align: right;
    }
  }

  p {
    @include tablet {
      order: 1;
    }
  }

  .lovelo {
    text-shadow: 0 10px 20px #718683;
    letter-spacing: -0.08em;
    font-size: 70px;
    line-height: 1;
    position: relative;

    @include tablet {
      font-size: 100px;
    }

    @include desktop {
      font-size: 150px;
    }
  }

  .subtitle {
    position: absolute;
    bottom: 0px;
    right: -5px;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;

    @include tablet {
      font-size: 14px;
      bottom: -3px;
    }

    @include desktop {
      bottom: 7px;
      font-size: 16px;
      // bottom: -32px;
      letter-spacing: 0.3rem;
    }
  }

  h1 {
    margin: 60px auto 0 auto;
    max-width: 70%;
    line-height: 1.5;
    font-size: 18px;
    font-weight: normal;

    @include tablet {
      line-height: 1.4;
      margin: 0;
      text-align: left;
      max-width: 4000px;
      font-size: 22px;
    }

    @include desktop {
      // max-width: 500px;
      font-size: 26px;
    }
  }
}
