@import "../../ui/variables.scss";

.logo {
  fill: var(--white);
  stroke: var(--text-color);
  stroke-width: 5px;
}

.dark-mode {
  .logo {
    stroke: var(--white);
  }
}
