@import "../../ui/media.scss";

.games {
  font-size: 14px;

  @include tablet {
    font-size: 16px;
  }

  ul {
    display: none;
    padding: 0 0 50px 0;
  }

  li:nth-child(even) {
    background-color: #f7f7f7;
  }

  &.show-table {
    ul {
      display: block;
    }
  }

  .games-top-wrapper {
    position: fixed;
    display: flex;
    width: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    display: none;
  }

  .games-top {
    max-width: $tablet;
    margin: 0 auto;
    width: 100%;
    align-items: flex-end;
    padding: 40px 16px 0 16px;
    background-color: white;
    border-bottom: 1px solid #eaeaea;
    display: none;
    pointer-events: none;
    max-width: $tablet;
    margin: 0 auto;

    @include landscape {
      padding: 40px 25px 0 25px;
    }

    @media (min-width: 818px) {
      padding: 40px 0 0 0;
    }
  }

  &.show-header {
    .games-top {
      display: flex;
    }
  }

  // .button-wrapper {
  //   position: absolute;
  //   top: -60px;
  //   left: 0;
  //   z-index: 11;
  // }

  li {
    display: flex;
  }

  .games-header {
    flex: 0 0 calc(100% - 135px);
    max-width: calc(100% - 135px);

    @include tablet {
      flex: 0 0 calc(100% - 200px);
      max-width: calc(100% - 200px);
    }
  }

  .date-and-name {
    flex: 0 0 135px;
    position: relative;
    max-width: 135px;
    text-align: center;

    @include tablet {
      flex: 0 0 200px;
      max-width: 200px;
    }
  }

  .header-names {
    display: flex;
    padding: 0 0 2vh 0;
  }

  .game-cell {
    width: calc(100% / 6);
    text-align: center;
    line-height: 36px;
    font-size: 20px;

    @include tablet {
      line-height: 48px;
    }

    &.correct {
      font-weight: bold;
      background-color: #7bf1b0;
      border-bottom: 1px solid #7bf1b0;
      color: #366b4b;
    }

    &.incorrect {
      background-color: #ffaeae;
      background-color: #eaeaea;
      background-color: #f7f7f7;
      border-bottom: 1px solid #f7f7f7;
      color: #aaa;
    }
  }

  .game-cell,
  .game-name {
    border-bottom: 1px solid #eaeaea;
    white-space: nowrap;
  }

  .game-name {
    padding: 17px 0 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    text-align: left;
    line-height: 1;

    @include tablet {
      padding: 22px 0 10px 0;
      max-width: 200px;
    }
  }

  .header-name {
    width: calc(100% / 6);
    white-space: nowrap;
    position: relative;

    & > div {
      transform-origin: center;
      transform: rotate(-90deg);

      @include tablet {
        transform: rotate(0);
        text-align: center;
      }
    }
  }

  .game-date {
    font-style: italic;
    font-size: 12px;
    line-height: 1;
    position: absolute;
    top: 2px;
    left: 0;
    color: #596579;
    color: #777;

    @include tablet {
      top: 5px;
      font-size: 14px;
    }
  }

  .games-single-question {
    border-bottom: 1px solid #eaeaea;

    .date-and-name {
      text-align: left;
      height: 100px;
      line-height: 100px;

      @include tablet {
        height: auto;
        line-height: 1.8;
        padding: 5px 0;
      }
    }

    .value-name {
      flex: 0 0 calc(100% / 6);
      max-width: calc(100% / 6);
      position: relative;
      padding: 0 0 2vh 0;

      @include tablet {
        padding: 5px 0;
      }

      & > div {
        transform-origin: center;
        transform: rotate(-90deg);
        white-space: nowrap;

        @include tablet {
          transform: rotate(0);
          text-align: center;
        }
      }

      &.incorrect {
        background-color: #f7f7f7;
        color: #aaa;
      }

      &.correct {
        background-color: #7bf1b0;
        color: #3c8c5c;
        color: #000;
        font-weight: bold;
      }

      &.winner {
        background-color: var(--text-color);
        color: var(--white);
      }

      .total {
        font-size: 30px;
      }
    }

    .value-wrapper {
      position: relative;
      display: flex;
      flex: 0 0 calc(100% - 135px);
      max-width: calc(100% - 135px);
      align-items: flex-end;

      @include tablet {
        flex: 0 0 calc(100% - 200px);
        max-width: calc(100% - 200px);
      }
    }
  }

  .games-single-question.total {
    .date-and-name {
      line-height: 1;
    }
  }

  .total {
    .date-and-name {
      height: auto;

      align-self: center;
    }

    .value-name {
      padding: 4px 0;
      text-align: center;
      font-weight: 700;
      font-size: 30px;
    }
  }

  .exodus {
    .date-and-name {
      height: 80px;
      line-height: 80px;

      @include tablet {
        height: auto;
        line-height: 1.8;
      }
    }
  }

  .in-lead {
    background-color: #fbfaa9;
  }

  .header-rows {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;

    .game-cell {
      font-size: 16px;
      line-height: 16px;
      min-height: 90px;
      position: relative;
      background-color: white;
      border-bottom: 1px solid #999;

      @include tablet {
        font-size: 18px;
        line-height: 18px;
        min-height: 110px;
      }
    }

    .date-and-name {
      background-color: white;
      display: block;
      border-bottom: 1px solid #999;
    }

    span {
      transform: rotate(-90deg);
      display: block;
      transform-origin: left center;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin: 0 0 0 -2px;
      font-weight: 700;

      @include tablet {
        bottom: 10px;
      }
    }
  }

  .games-countries {
    display: flex;

    .date-and-name {
      text-align: left;
      line-height: 36px;
    }

    .flag {
      font-size: 20px;
      padding: 0;

      &.incorrect {
        filter: grayscale(1);
        opacity: 0.4;
      }

      @include tablet {
        font-size: 30px;
        padding: 0;
        line-height: 36px;
      }
    }
  }
}
