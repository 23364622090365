@import "../../ui/media.scss";

.posts {
  margin: 0 -16px 200px -16px;

  @include landscape {
    margin: 0 -25px 200px -25px;
  }

  @media (min-width: 818px) {
    margin: 0 0 200px 0;
  }

  ul {
    border-top: 1px solid #eaeaea;
  }

  .name-and-time {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .post-name {
    font-weight: 500;
  }

  .post-date {
    font-weight: 500;
    color: #596579;
    font-size: 13px;
  }

  .post-body {
    line-height: 1.5;
    overflow: hidden;
  }

  .post-error {
    color: var(--error);
    font-weight: 500;

    transition: opacity 300ms ease;
    opacity: 0;
  }

  &.has-error {
    .post-error {
      opacity: 1;
    }
  }

  .post {
    border-bottom: 1px solid #eaeaea;
    padding: 10px 16px 16px 16px;

    @include landscape {
      padding: 10px 25px 16px 25px;
    }

    &:nth-child(odd) {
      background-color: #f7f7f7;
    }
  }

  .input-message,
  .input-name {
    border: 1px solid #eaeaea;
    font-size: 16px;
    border-radius: 5px;
    display: block;
    margin: 0 0 10px 0;
    width: 100%;
    display: block;
    font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
  }

  .input-name {
    padding: 0 10px;
    height: var(--button-line-height);
    line-height: var(--button-line-height);

    @include tablet {
      height: var(--button-line-height-tablet);
      line-height: var(--button-line-height-tablet);
    }
  }

  .input-message {
    padding: 10px;
    min-height: 100px;
    resize: vertical;
  }

  form {
    padding: 16px;

    @include landscape {
      padding: 25px;
    }

    @media (min-width: 818px) {
      padding: 25px 0;
    }

    button {
      margin: 0 0 16px 0;
    }
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin: 0 0 30px 0;
  }

  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
