@import "./ui/variables.scss";
@import "./ui/media.scss";

#curtain {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  transition: opacity 500ms ease;
  will-change: opacity;
  opacity: 1;
  pointer-events: all;
  z-index: 50;
}

.page {
  padding: 60px 0;
  min-height: 100vh;
  padding: 120px 0 300px 0;
  overflow: hidden;

  @include tablet {
    padding: 160px 0 300px 0;
  }

  @include desktop-plus {
    padding: 160px 0 400px 0;
  }
}

.loaded #curtain {
  opacity: 0;
  pointer-events: none;
}

.app {
  position: relative;
  // overflow: hidden;
  font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  &.dark-mode {
    background-color: var(--background-dark);
  }

  &.stripped {
    header {
      display: none;
    }
  }
}

.home {
  section {
    margin: 0 0 90px 0;

    @include tablet {
      margin: 0 0 100px 0;
    }
  }
}

article {
  max-width: $desktop-plus;
  margin: 0 auto;

  h2 {
    position: relative;
    margin: 0 0 30px 0;

    &:after {
      content: "";
      height: 1px;
      position: absolute;
      right: 0;
      top: 18px;
      background: linear-gradient(-90deg, transparent, var(--text-color) 40%);

      @include tablet {
        top: 21px;
        width: calc(100% - 210px);
      }
    }
  }
}

.dark-mode {
  article {
    h2 {
      &:after {
        background: linear-gradient(
          -90deg,
          transparent,
          var(--text-color-dark) 40%
        );
      }
    }
  }
}

section {
  padding: 0 16px;

  @include landscape {
    padding: 0 25px;
  }

  &.split {
    & > div {
      max-width: $desktop-plus;
      margin: 0 auto;

      @include tablet {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;

        article {
          width: 50%;
          max-width: 660px;
          padding: 0 30px 0 0;
          margin: 0;
          flex: 0 0 auto;

          &:last-child {
            max-width: 550px;
            display: block;
            padding: 0 0 0 30px;
          }
        }
      }
    }

    @include desktop {
      article {
        padding: 0 80px 0 0;
      }
    }
  }
}

.dark-mode {
  section {
    &.highlighted {
      background-color: var(--background-card-dark);
      border-top: 2px solid var(--text-color-dark-emphasized);
      border-bottom: 2px solid var(--text-color-dark-emphasized);
      box-shadow: 0 0 20px -3px var(--text-color);
      color: var(--text-color-dark-emphasized);

      h2 {
        color: var(--text-color-dark-emphasized);
      }

      &:before {
        background-color: var(--background-card-dark);
        top: -2px;
        border-top: 2px solid var(--text-color-dark-emphasized);
      }
    }
  }
}

blockquote {
  margin: 0 0 16px 0;
  font-family: "Playfair display";
  font-style: italic;
  line-height: 1.2;
  font-size: 30px;

  @include tablet {
    font-size: 50px;
  }
}

cite {
  font-size: var(--font-regular);
  color: var(--text-color-cite);
  font-style: normal;
}

.dark-mode {
  cite {
    color: var(--text-color-dark);
    opacity: 0.6;
  }
}

.relative {
  position: relative;
}

.disc {
  list-style: disc;
  margin: 0 0 0 20px;

  li {
    line-height: 1.5;
    margin: 0 0 16px 0;
  }
}

.flex {
  justify-content: space-between;
  display: flex;
}

// .app {
//   & > .height-anim {
//     margin: 100px 0 0 0;
//   }
// }
