@import "../../ui/media.scss";

.works {
  max-width: $desktop;
  margin: 100px auto;

  @include desktop {
    padding: 0 25px;
    margin: 200px auto;
  }
}

.home .works h2:after {
  width: calc(100% - 74px);

  @include tablet {
    width: calc(100% - 100px);
  }
}

.works-images {
  margin: 30px 0;

  @include landscape {
    display: flex;
    justify-content: space-between;
  }

  & > a {
    display: block;
    margin: 30px 0;

    @include landscape {
      flex: 0 0 45%;
      margin: 0;
    }
  }

  .image {
    box-shadow: #3b3e4d 0px 0px 60px -20px;
    overflow: hidden;
    border-radius: 10px;
  }
}
