@import "../../ui/media.scss";
@import "../../ui/variables.scss";
$header-height: 60px;

.average header {
  position: absolute;
  width: 100%;

  a {
    color: var(--text-color);
  }

  @include tablet {
    height: $header-height;
    display: block;
  }

  article {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0;
  }

  .logo-wrapper {
    width: 28px;
    margin: 10px 0 0 0;
    flex-shrink: 0;
    flex-grow: 0;

    button {
      width: 28px;
    }
  }

  nav {
    margin: 0 -16px 0 0;

    @include landscape {
      margin: 0 -25px 0 0;
    }
  }

  li {
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    line-height: $header-height;
    padding: 0 16px;

    @include landscape {
      padding: 0 25px;
    }
  }
}
