@import "../../ui/media.scss";

footer {
  height: 300px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  a {
    // color: var(--text-color);
    // font-weight: 400;
    text-decoration: none;
    position: relative;
    top: -4px;
  }

  @include desktop-plus {
    height: 400px;
  }

  .footer-bg {
    height: 100px;
    position: relative;
    z-index: 6;

    @include tablet {
      height: 150px;
    }

    & > svg {
      height: 100%;
      width: 100%;
      max-width: 10000px;
      display: block;
      opacity: 1;
    }
  }

  .disclaimer {
    opacity: 0.7;
  }

  & > .footer-inner {
    height: 200px;
    text-align: center;
    padding: 10px 0 0 0;
    background-color: var(--white);
    position: relative;
    z-index: 5;

    @include tablet {
      height: 150px;
    }

    @include desktop-plus {
      padding: 50px 0 0 0;
      height: 250px;
    }
  }

  svg {
    width: 40px;
    opacity: 0.7;
  }
}
